// This file contains functions used in Order Grid -- functions that filter
// orders using API calls


import { camelCaseToUnderscores } from '../../utils/helpers'
import { portalRequestor, saFetch } from './v1/requestors'

export const fetchuserinfo = async () => {
  const url = `user/info`
  return await portalRequestor({ type: 'get', url, caller: 'fetchuserinfo' })
}
