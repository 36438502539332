import React from 'react'
import {
  clearStorage,
  keepInStorage
} from '../../../infrastructure/service/localStorage/helper'

export const clearProviderAuth = () => {
  clearStorage('providerAuth')
  // clearStorage('clientId')
}
export const clearSaAuth = () => {
  // clearStorage('userAuth')
  clearStorage('token')
}
export const clearSaUser = () => {
  clearSaAuth()
  clearStorage('saUser')
}

const keepSaAuth = (auth) => {
  // keepInStorage('userAuth', { auth })
  keepInStorage('token', auth.access_token)
}
export const keepSaUser = (user) => {
  keepInStorage('saUser', user)
  user && keepSaAuth(user.auth)
}
export const keepProviderAuth = (auth) => {
  keepInStorage('providerAuth', auth)
}

export const SA_AUTH = {
  CLIENT_ID: {
    urlType: 'root',
    url: '/auth/oauth/providerClientId',
    method: 'GET',
    caller: 'SA_AUTH CLIENT_ID!',
    validator: ({ provider }) => !!provider
  },
  LOGIN: {
    urlType: 'root',
    url: '/auth/oauth/login',
    method: 'POST',
    caller: 'SA_AUTH LOGIN JWT!',
    validator: ({ token, provider }) => !!provider && !!token
  }
}
