import { portalRequestor } from '../../../../infrastructure/api/v1/requestors'

export const fetchConfigurations = async ({
  type,
  entityId,
  projectId = 0,
  channelId = 0
}) => {
  const url = `configurations/groups?type=${type}&entity_id=${entityId}&shop_id=${projectId}&marketplace_id=${channelId}`

  return await portalRequestor({
    type: 'get', //IntegrationSettings, loadConfigurarions  serviceInfo, configurations
    url,
    caller: 'fetchConfigurations'
  })
}

export const fetchConfigurationGroupItems = async ({
  type,
  entityId,
  groupId,
  salesChannelId,
  configId = '',
  configType = ''
}) => {
  const url = `configurations/group_item?type=${type}&entity_id=${entityId}&config_entity_type=${configType}&config_entity_id=${configId}&group_id=${groupId}&marketplace_id=${salesChannelId}`
  return portalRequestor({
    type: 'get',
    url,
    caller: 'fetchConfigurationGroupItems'
  })
}

export const saveConfigurations = async (dtoObj) => {
  const url = `portal/v1/configurations/save`
  const fetchParams = dtoObj
  return await portalRequestor({
    type: 'post',
    url,
    fetchParams,
    caller: 'saveConfigurations'
  })
}

export const fetchVendorConfig = async ({
  vendorId = null,
  marketplaceId = null
}) => {
  if (!vendorId) {
    return null
  }
  const marketplace = `${marketplaceId !== null ? 'marketplace_id=' + marketplaceId : ''
    }`
  const paramStr = '?' + marketplace
  const url = `vendors/${vendorId}/configs${paramStr}`

  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchVendorConfig'
  })
}

export const fetchIntegrations = async ({ client = null, platform = null }) => {
  const url = `platform/config/vendor/?shop=${client}&page=1&platform=${platform}`
  return await portalRequestor({
    type: 'post',
    url,
    caller: 'fetchIntegrations'
  })
}

export const fetchClients = async ({ client = null }) => {
  const url = `clients/${client !== null ? client : ''}`
  console.log('fetchClients url', url)
  return await portalRequestor({ type: 'get', url, caller: 'fetchClients' })
}

export const fetchVendors = async () => {
  const url = `vendors`
  return await portalRequestor({ type: 'get', url, caller: 'fetchVendors' })
}
export const fetchVendor = async ({ id }) => {
  const url = `vendors/${id !== null ? id : 0}`
  return await portalRequestor({ type: 'get', url, caller: 'fetchVendor' })
}
export const fetchProjects = async ({ clientId }) => {
  const url = `shops`
  return await portalRequestor({ type: 'get', url, caller: 'fetchProjects' })
}
export const fetchChannels = async ({ clientId }) => {
  const url = `marketplaces`
  return await portalRequestor({ type: 'get', url, caller: 'fetchChannels' })
}
export const fetchCurrencies = async () => {
  const url = 'marketplaces/currency'
  return await portalRequestor({ type: 'get', url, caller: 'fetchCurrencies' })
}
export const fetchClientProjects = async ({ clientId }) => {
  const url = `clients/${clientId}/shops`
  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchClientProjects'
  })
}

export const fetchProjectVendors = async ({ projectId = null }) => {
  const url = `shop/${projectId}/vendors`
  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchProjectVendors'
  })
}
export const fetchAllVendors = async () => {
  const url = `vendors`
  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchAllVendors'
  })
}

export const fetchProjectChannels = async ({ projectId = null }) => {
  const url = `shop/${projectId}/marketplaces`
  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchProjectChannels'
  })
}
export const updateVendorScriptStatus = async ({
  vendorId,
  channelId,
  status
}) => {
  const fetchParams = {
    status: status,
    marketplace_id: channelId
  }
  const url = `vendors/${vendorId}/status`
  return await portalRequestor({
    type: 'post',
    url,
    fetchParams,
    caller: 'updateVendorScriptStatus'
  })
}
export const fetchPlatforms = async () => {
  // const client = `${clientId !== null ? 'client_id=' + clientId : ''}`
  // const paramStr = '?' + client
  // const param = paramStr.length > 1 ? paramStr : ''
  const url = `platforms`
  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchPlatforms'
  })
}
