import React from 'react'
import { useNavigate } from 'react-router-dom'
import ScSimpleTable from '../../../shared/components/tables/ScSimpleTable'


const ProductsTable = ({ columns, products }) => {
  const navigate = useNavigate()
  const handleRowClick = ({ marketplace_id, market_product_id, id }) => {
    navigate(
      `/home/order_hub/product_details/market_place_id=${marketplace_id}/product_id=${market_product_id}/sa_product_id=${id}`
    )
  }
  const handleNav = ({ marketplace_id, market_product_id, id }) => {
    return `/home/order_hub/product_details/market_place_id=${marketplace_id}/product_id=${market_product_id}/sa_product_id=${id}`
  }
  const getProductFieldText = (code, product) => {
    return typeof product[code] == 'string'
      ? product[code]
      : product[code] === false || product[code] === null
        ? ''
        : JSON.stringify(product[code])
  }
  // const hasProducts = !!products.length

  if (!Array.isArray(columns)) return

  const getColumnTitle = (field) => {
    return typeof field === 'string' ? field.replace('_', ' ') : field?.title
  }

  // const getColumnCode = (field) => {
  //   return typeof field === 'string' ? field : field?.code
  // }

  const getRenderedValue = (row, field) => {
    const { renderer, code } = field
    // console.log('getRendered', renderer, field, getProductFieldText(code, row))
    if (typeof renderer === 'function')
      return renderer(getProductFieldText(code, row))
    if (typeof code === 'string') return row[code]
  }
  return (
    <>
      <ScSimpleTable
        keyField={'id'}
        fieldsList={columns}
        preselected={[]}
        dataRows={products}
        onRowClick={handleRowClick}
        getRenderedValue={getRenderedValue}
        getColumnTitle={getColumnTitle}
        getNav={handleNav}
      />
    </>
  )
}

export default ProductsTable
