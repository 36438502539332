import { portalRequestor } from '../../../infrastructure/api/v1/requestors'
export const FETCH_ITEMS_TO_MODERATE_URL =
  'personalizationModeration/grid/items'

export const fetchItemsToModerate = async (params) => {
  const { per_page, ...restParams } = params
  const url = FETCH_ITEMS_TO_MODERATE_URL
  const fetchParams = { ...restParams, per_page }


  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchItemsToModerate',
    fetchParams: fetchParams
  })
}
