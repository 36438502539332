import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  SnowTableRow,
  SnowTableCell,
  SnowTypography,
  SnowList,
  SnowListItem
} from '../../../../shared/components/snowComponents'
import ScStatusChip from '../../../../shared/components/ScStatusChip'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const OrderTableRowView = styled(SnowTableRow)`
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.mainPalette.grey.light};
  }
`

const OrderNumberCell = styled(SnowTableCell)`
  &.MuiTableCell-root {
    ${({ theme }) => css`
      vertical-align: top;
      color: ${theme.mainPalette.color.link};
      font-weight: ${theme.fontWeight.heavy};
    `}
  }
`
const OrderDataCell = styled(SnowTableCell)`
  &.MuiTableCell-root {
    ${({ theme }) => css`
      vertical-align: top;
      color: ${theme.mainPalette.typography.main};
    `}
  }
`

const ProductItemTR = styled(SnowListItem).attrs({
  disableGutters: true,
  dense: true
})`
  &.MuiListItem-root {
    justify-content: space-between;
    align-items: flex-start;
  }
  border: none !important;
  &:hover: {
    background-color: transparent !important;
  }
`

const OrderDataView = styled(SnowTypography).attrs(({ theme }) => ({
  component: 'p',
  variant: 'inherit',
  fontWeight: theme.fontWeight.main
}))`
  span {
    text-transform: lowercase;
  }
`

const QtyBadgeView = styled.div`
  margin-right: 4px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dfe3e8;
  border: 2px solid #fff;
  font-size: 12px;
  line-height: 1;
  color: black;
  vertical-align: middle;
`

const ProductNameView = styled.div`
  margin-right: 4px;
  flex: 0 1 200px;
  min-width: 200px;
`

const OrderRow = (props) => {
  const navigation = useNavigate()
  // function to format order placement date
  const getDate = (timestamp) => {
    const dateArray = new Date(timestamp).toUTCString().split(' ')
    const timeArray = new Date(timestamp).toLocaleTimeString().split(':')
    return (
      <OrderDataView>
        {dateArray[2]} {dateArray[1]} at {timeArray[0]}:{timeArray[1]}{' '}
        <span>{timeArray[2].split(' ')[1]}</span>
      </OrderDataView>
    )
  }

  const getProductItemCell = (orderItems) => {
    const hasItems = orderItems.length
    return hasItems ? (
      <Fragment>
        <SnowList disablePadding={true}>
          {orderItems.map(
            ({ name, snowaxis_product_name, qtyOrdered, status }, idx) => (
              <ProductItemTR key={idx}>
                <div style={{ display: 'flex' }}>
                  <QtyBadgeView>{qtyOrdered}</QtyBadgeView>
                  <ProductNameView>
                    {snowaxis_product_name ? snowaxis_product_name : name}
                  </ProductNameView>
                </div>
                <ScStatusChip status={status} />
              </ProductItemTR>
            )
          )}
        </SnowList>
      </Fragment>
    ) : (
      'none listed'
    )
  }
  // function to determine badge color based on status string
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'ready':
        return 'success'

      case 'new':
      case 'processing':
      case 'sent':
      case 'open':
        return 'warning'

      case 'complete':
      case 'shipped':
      case 'closed':
        return 'secondary'

      case 'holded':
      case 'holded_admin':
      case 'error_validation':
      case 'hold':
        return 'danger'

      case 'cancelled':
        return 'dark'

      default:
        return 'danger'
    }
  }
  // function to display each order vendor name
  const getVendor = (vendorObj) => {
    const { vendor } = vendorObj
    const vendorName = vendor
    return vendorName
  }

  // function to display vendor status
  const getVendorStatus = (vendorOrderObj) => {
    let vendorStatus = vendorOrderObj.status
    vendorStatus = vendorStatus.replace('_', ' ')
    return vendorStatus
  }

  const getPaymentMethod = (paymentMethod) => {
    if (paymentMethod !== null) {
      paymentMethod = paymentMethod.replace('_', ' ')
      return paymentMethod
    } else {
      return ''
    }
  }

  // function to display prices in $0.00 format
  const formatToTwoDecimals = (price) => {
    if (price === null) {
      return false
    }
    // format the price to 2 decimal places and add dollar sign ($)
    const priceArray = price.split('.')
    return `$${priceArray[0]}.${priceArray[1].slice(0, 2)}`
  }

  const { row } = props
  // if(row.orderItems.length>1)
  useEffect(() => {
    console.log('Order Row', row)
  }, [row])
  return (
    <OrderTableRowView component={Link} to={`../order_details/${row.id}/`} key={row.id}>
      <OrderNumberCell>{row.orderNumber}</OrderNumberCell>
      <OrderDataCell>{row.store}</OrderDataCell>
      <OrderDataCell>{getDate(row.createdAt)}</OrderDataCell>
      <OrderDataCell colSpan={2}>
        {getProductItemCell(row.orderItems)}
      </OrderDataCell>
      <OrderDataCell>{row.customerName}</OrderDataCell>
      <OrderDataCell>{getPaymentMethod(row.paymentMethod)}</OrderDataCell>
      <OrderDataCell>
        {row.vendors.length > 0
          ? row.vendors.map((vendor, idx) => (
            // use <br> in order to stack vendors vertically
            <div key={idx}>
              {/*{console.log(this)}*/}
              {getVendor(vendor)}
              <br />
            </div>
          ))
          : 'none listed'}
      </OrderDataCell>
      <OrderDataCell align={'right'}>
        <ScStatusChip status={row.portal_status} />
      </OrderDataCell>
    </OrderTableRowView>
  )
}
const OrderRowWithLocation = (props) => {
  const location = useLocation()
  return <OrderRow location={location} {...props} />
}
export default OrderRowWithLocation

OrderRow.propTypes = {
  row: PropTypes.shape().isRequired
}
