import { atom, selector, useRecoilValue } from "recoil";
import { orderLineItemModel } from "./orderLineItemModel";
import { orderVendorModel } from "./orderVendorModel";
import orderVendorOrderModel from "./orderVendorOrderModel";

const currencySymbol = { USD: "$" };
export const getCurrencySymbol = (currencyCode) => currencySymbol[currencyCode];
export const currentOrderState = atom({
  key: "currentOrderState",
  default: {}
});
export const getConsistentObjOrThrow = (expectedKeysArray, obj, required) => {
  console.log("Object", obj);
  console.log("expectedKeysArray", expectedKeysArray);
  console.log("required", required);

  const result = expectedKeysArray.reduce((acc, key) => {
    if (required.includes(key) && !(key in obj)) throw { [key]: `${key}` };
    return { ...acc, [key]: obj[key] };
  }, {});
  console.log("getConsistentObjOrThrow", result);
  return result;
};

function getOrderItemProductLink(
  channelId,
  { platformProductId, saProductId }
) {
  const url = `/home/order_hub/product_details/market_place_id=${channelId}/product_id=${platformProductId}/sa_product_id=${saProductId}`;
  return url;
}

function getTaxType(taxObj) {
  let taxType = "";
  let taxPercent = 0;

  if (taxObj.title !== "") {
    taxType = taxObj.title;
  }
  if (taxObj.rate) {
    taxPercent = taxObj.rate * 100;
  }
  return `${taxType} ${taxPercent.toFixed(1)}%`;
}

const getTracking = (carrierInfo) => {
  const { carrier_code, tracking_num } = carrierInfo;
  const trackingLink = getTrackingNumberLink({
    carrier: carrier_code,
    trackingNumber: tracking_num
  });
  return { carrierName: carrier_code, trackingNumber: tracking_num, trackingLink };

};
const getShipments = (orderItem, shippingInfo) => {
  const itemShipmentIds = orderItem.shippingIds ?? [];
  const availableItemShipments = shippingInfo.shipments.filter(({ shipping_id }) => itemShipmentIds.includes(shipping_id));
  console.log("getShipment", { availableItemShipments, orderItem, shippingInfo });
  const itemShipments = availableItemShipments.map((shipment) => ({
    shippingId: shipment.shipping_id,
    status: shipment.status,
    method: shipment.method,
    timeFrame: shipment.time_frame,
    tracks: shipment.carrier_info.map(carrierInfo => getTracking(carrierInfo))
  }));
  return itemShipments;
  // const carrierInfo = getCarrierInfo({ shippingInfo }, orderItem)
  // const carrierName = getCarrierName(carrierInfo)
  // const trackingNumber = getTrackingNumber(carrierInfo)


};

function getTrackingNumberLink({ carrier, trackingNumber }) {
  // link the tracking number to the appropriate carrier website
  // for UPS:
  if (carrier === "ups") {
    return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT/trackdetails`;
    // for USPS:
  } else if (carrier === "usps") {
    return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`;
    // for FedEx
  } else if (carrier === "fedex") {
    return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
  } else if (carrier === "lasership") {
    return `http://www.lasership.com/track/${trackingNumber}`;
  }
  // if no carrier is listed, link to no website
  return "";
}

//todo: implement mapper from raw data to model
const currentOrderModel = selector({
  key: "currentOrderModel",
  get: ({ get }) => {
    const rawData = get(currentOrderState);

    console.log("currentOrderModel rawData", rawData);
    if (rawData.data === null || !Object.keys(rawData).length) {
      return null;
    }
    const {
      id,
      shipping_method: shippingMethod,
      order_number: orderNumber,
      portal_status: portalStatus,
      // personalization_approved: personalizationApproved,
      marketpalce_id: channelId,
      store: channelName,
      customized,
      platform_status: platformStatus,
      vendors,
      discount_lines: discountLines,
      customer_group: customerGroup,
      customer_email: customerEmail,
      customer_name: customerName,
      payment_info: { currency: currencyCode },
      note,
      // status,
      created_at: createdAt,
      snow_shipping_method: snowShippingMethod,
      tax_amount,
      shop_id: projectId,
      comments,
      discount,
      editable,
      platform,
      shipping_info: shippingInfo,
      orderhub_status: orderhubStatus,
      packingslip,
      grand_total,
      vendor_orders,
      order_items,
      errors,
      ...legacy
    } = rawData;

    const orderItems = order_items
      .map((order_item) => orderLineItemModel(order_item))
      .map((orderItem) => {
        const productLink = getOrderItemProductLink(channelId, orderItem);
        const shipments = getShipments(orderItem, shippingInfo);
        return {
          ...orderItem,
          productLink,
          shipments
        };
      });


    // function to check for errors in order.order_item object, as well as in
    // order.vendors object
    const hasOrderErrors = (orderItems) => {
      const hasErrors = false;
      const hasOrderItemsError =
        orderItems &&
        orderItems.find((item) => {
          // if item.errors is not null
          return item.errors && Object.keys(item.errors).length;
        });

      const hasVendorsError =
        vendors.length &&
        vendors.find((vendorObj) => {
          // if vendorObj.errors is not null
          // check if vendorObj.errors is NOT empty
          return vendorObj.errors && Object.keys(vendorObj.errors).length;
        });

      const hasOrderLevelError = errors.length || Object.keys(errors).length;

      return hasOrderLevelError || hasVendorsError || hasOrderItemsError;
    };
    const calculatedData = {
      //business-logic is moved from OrderPayment.js
      hasDiscount: discountLines && discountLines.length,

      totalDiscount: Number(discount).toFixed(2),

      itemsCount: order_items.reduce((acc, product) => {
        return acc + product.qty_ordered;
      }, 0),

      taxTotal: Number(tax_amount).toFixed(2),
      hasErrors: hasOrderErrors(orderItems),
      subtotal: (
        order_items.reduce(
          (acc, product) => acc + product.price * product.qty_ordered,
          0
        ) - discount.toFixed(2)
      ).toFixed(2),

      grandTotal: (1 * grand_total).toFixed(2),

      shippingTotal: Number(shippingInfo.shipping_total).toFixed(2),

      uniqTaxLines: [
        ...new Set( //use Set to get rid of duplicates
          order_items.filter(({ tax_lines }) => Boolean(tax_lines)).reduce(
            //traverse through all order_items to reduce tax_lines into taxTyped array
            (acc, { tax_lines }) => [
              ...acc,
              ...tax_lines.reduce(
                (acc2, line) => [...acc2, getTaxType(line)],
                []
              )
            ],
            []
          )
        )
      ]
    };
    console.log("currentOrderModel updated");

    return {
      ...legacy,
      customerName,
      errors,
      orderItems,
      currencyCode,
      discount,
      discountLines,
      vendors: vendors.map((v) => orderVendorModel(v)),
      vendorOrders: vendor_orders.map((vo) => orderVendorOrderModel(vo)),
      id,
      platformStatus,
      note,
      calculatedData,
      customerEmail,
      customized,
      channelId,
      channelName,
      createdAt,
      comments,
      orderNumber,
      portalStatus,
      shippingMethod,
      snowShippingMethod,
      orderhubStatus,
      projectId,
      editable,
      platform,
      packingslip,
      legacy
    };
  }
});
export const useCurrentOrder = () => {
  const orderModel = useRecoilValue(currentOrderModel);
  return orderModel;
};
