import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { fetchSingleOrder } from '../infrastructure/fetches'
import { useLocation, useNavigate } from 'react-router-dom'
import OnHoldModal from './Modals/OnHoldModal'
import CancelModal from './Modals/CancelModal'
import CancelItemsModal from './Modals/CancelItemsModal'
import RevalidateModal from './Modals/RevalidateModal'
import ConfirmationModal from './Modals/ConfirmationModal'
import ScStatusChip from '../../../shared/components/ScStatusChip'
import DetailHeader from './DetailHeader/DetailHeader'
import HeaderButtons from './DetailHeader/components/buttons/HeaderButtons'
import DetailBody from './DetailBody'
import { useResetRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useCurrentOrder, currentOrderState } from './data/currentOrderState'
import { setScPageHeaderTitleCmd } from '../../app/data/currentHeadingState'
import OrderItemResolvingModal from './Modals/OrderItemResolvingModal'
import { currentOrderModalsData } from './data/orderUxState'
import { ScEntityDetailsPage } from '../../../shared/components/LayoutComponents'
import DetailSidebar from './DetailSidebar/DetailSidebar'
import useSnowLoggerSetup, { LogLevel } from '../../../shared/service/helpers/SnowLogger'

const OrderDetail = (props) => {
  // const [order, setOrder] = useState(null)
  useSnowLoggerSetup({
    uxScope: 'ORDER',
    level: LogLevel.ERROR,
    color: ['yellow', 'green'],
    devOnly: true
  })
  const [showOnHoldModal, setShowOnHoldModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showCancelItemsModal, setShowCancelItemsModal] = useState(false)
  const [showRevalidateModal, setShowRevalidateModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [response, setResponse] = useState({})
  const navigate = useNavigate()
  const location = useLocation()
  const setCurrentOrderAtom = useSetRecoilState(currentOrderState)
  const resetCurrentOrderAtom = useResetRecoilState(currentOrderState)
  const orderModel = useCurrentOrder()
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)
  const itemToResolve = useRecoilValue(
    currentOrderModalsData('orderItemResolving')
  )
  const { meta, orderNumber, createdAt, portalStatus, channelName } =
    orderModel ?? {}

  function getDate(timestamp) {
    return <>{` ${format(new Date(timestamp), 'PPPpaa')} `}</>
  }

  const AdminLink = ({ name = '', url = '' }) => {
    if (!name || !url) return <></>

    return (
      <a href={url} target={'_blank'} rel={'noreferrer'}>
        {name}
      </a>
    )
  }

  const OrderTitle = () => {
    const linkToAdmin = !!orderNumber && meta?.sc_order_link
    return (
      <>
        {linkToAdmin ? (
          <AdminLink name={orderNumber} url={linkToAdmin} />
        ) : (
          orderNumber
        )}
      </>
    )
  }

  const getOrder = () => {
    const orderID = location.pathname.split('/')[4]

    //#TODO make it where if it 404 it will redirect to the right page
    fetchSingleOrder({ orderID }).then((d) => {
      console.log('fetchSingleOrder resp', d)
      const { data } = d
      if (data) {
        setCurrentOrderAtom(data)
        // setOrder(data)
      }
    })
  }
  // when component mounts, immediately save the URL in local storage so the user can go back to it if they click into the PRODUCT view and then want to return to the order view
  const saveCurrentURL = () =>
    localStorage.setItem('orderDetailURL', location.pathname)

  const toggleShowOnHoldModal = () => {
    setShowOnHoldModal(!showOnHoldModal)
  }

  const toggleShowCancelModal = () => {
    setShowCancelModal(!showCancelModal)
  }

  const toggleShowCancelItemsModal = () => {
    setShowCancelItemsModal(!showCancelItemsModal)
  }

  const toggleShowRevalidateModal = () => {
    setShowRevalidateModal(!showRevalidateModal)
  }

  const toggleShowConfirmationModal = (response) => {
    setShowConfirmationModal(!showConfirmationModal)
    setResponse(response)
  }
  const toggleResendOrderItemsModal = () => { }
  const updateOrderInDetailState = (order) => {
    // setOrder(order)
    setCurrentOrderAtom(order)
  }
  useEffect(() => {
    const { id: orderId } = orderModel || { id: '' }
    setHeading(`Order #${orderId} `)

  }, [orderModel])

  useEffect(() => {
    getOrder()
    saveCurrentURL()
    return () => resetCurrentOrderAtom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log('orderModel!!!', orderModel)
  console.log('itemToResolve!!!', itemToResolve)

  return (
    <div style={{ color: '#637381' }}>
      {orderModel && (
        <div>
          <OnHoldModal
            order={orderModel}
            showModal={showOnHoldModal}
            toggleShowModal={toggleShowOnHoldModal}
            updateOrderInDetailState={updateOrderInDetailState}
            // updateOrderInGridState={props.updateOrderInGridState}
            toggleShowConfirmationModal={toggleShowConfirmationModal}
          />
          <CancelModal
            order={orderModel}
            showModal={showCancelModal}
            toggleShowModal={toggleShowCancelModal}
            updateOrderInDetailState={updateOrderInDetailState}
            // updateOrderInGridState={props.updateOrderInGridState}
            toggleShowConfirmationModal={toggleShowConfirmationModal}
          />
          <CancelItemsModal
            order={orderModel}
            showModal={showCancelItemsModal}
            toggleShowModal={toggleShowCancelItemsModal}
            updateOrderInDetailState={updateOrderInDetailState}
            // updateOrderInGridState={props.updateOrderInGridState}
            toggleShowConfirmationModal={toggleShowConfirmationModal}
          />
          <RevalidateModal
            order={orderModel}
            showModal={showRevalidateModal}
            toggleShowModal={toggleShowRevalidateModal}
            updateOrderInDetailState={updateOrderInDetailState}
            // updateOrderInGridState={props.updateOrderInGridState}
            toggleShowConfirmationModal={toggleShowConfirmationModal}
          />
          <ConfirmationModal
            order={orderModel}
            showModal={showConfirmationModal}
            toggleShowModal={toggleShowConfirmationModal}
            response={response}
          />
        </div>
      )}
      {!!itemToResolve && <OrderItemResolvingModal isOpen={true} />}
      {orderModel ? (
        <>
          <ScEntityDetailsPage
            title={<OrderTitle />}
            titleExt={
              <>
                {createdAt && getDate(createdAt)} {`from ${channelName}`}
              </>
            }
            subtitle={<ScStatusChip status={portalStatus} />}
            pageLinks={<HeaderButtons />}
            actionsContent={
              <DetailHeader
                toggleShowOnHoldModal={toggleShowOnHoldModal}
                toggleShowCancelModal={toggleShowCancelModal}
                toggleShowCancelItemsModal={toggleShowCancelItemsModal}
                toggleShowRevalidateModal={toggleShowRevalidateModal}
                toggleResendOrderItemsModal={toggleResendOrderItemsModal}
              />
            }
            entityContent={<DetailBody />}
            sidebarWidgets={<DetailSidebar />}
            sideBarWidth={400}
          />


        </>
      ) : (
        <div>None Found</div>
      )}
    </div>
  )
}

OrderDetail.propTypes = {
  // updateOrderInGridState: PropTypes.func.isRequired
}
export default OrderDetail
